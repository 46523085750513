<template>
  <div class="w-full max-w-md mx-auto">
    <!-- Label -->
    <div class="mb-4">
      <label :for="id" class="block text-sm font-medium text-gray-900">
        {{ label }}
      </label>
    </div>

    <div class="relative pb-10 h-12 select-none">
      <!-- Track background -->
      <div class="absolute top-3 h-1.5 w-full bg-gray-200 rounded-full z-0" />

      <!-- Filled track -->
      <div
        class="absolute top-3 h-1.5 bg-teal-700 rounded-full z-10"
        :style="{
          left: `${minPercentage}%`,
          width: `${maxPercentage - minPercentage}%`,
        }"
      />

      <!-- Thumbs -->
      <div
        v-for="type in ['max']"
        :key="type"
        class="absolute z-20"
        :style="{
          left: `calc(${type === 'min' ? minPercentage : maxPercentage}% - 8px)`,
          top: '0.375rem',
        }"
      >
        <div
          class="w-4 h-4 bg-primary rounded-full cursor-pointer shadow-md translate-y-[1px]"
          @mousedown="startDrag($event, type)"
          @touchstart="startDrag($event, type)"
        />
        <div class="absolute top-full mt-2 -translate-x-1/2 left-1/2 whitespace-nowrap">
          <div class="bg-primary text-white text-xs font-medium px-2 py-1 rounded">
            {{ type === "min" ? minValue : maxValue }} {{ unit }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onUnmounted } from "vue";

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => [0, 100],
  },
  min: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 100,
  },
  unit: {
    type: String,
    default: "%",
  },
  step: {
    type: Number,
    default: 1,
  },
  label: {
    type: String,
    default: "Kondition",
  },
  id: {
    type: String,
    default: "dual-range-slider",
  },
});

const emit = defineEmits(["update:modelValue", "change"]);

const minValue = computed({
  get: () => props.modelValue[0],
  set: (val) => emit("update:modelValue", [val, props.modelValue[1]]),
});

const maxValue = computed({
  get: () => props.modelValue[1],
  set: (val) => emit("update:modelValue", [props.modelValue[0], val]),
});

const minPercentage = computed(
  () => ((minValue.value - props.min) / (props.max - props.min)) * 100
);
const maxPercentage = computed(
  () => ((maxValue.value - props.min) / (props.max - props.min)) * 100
);

// Dragging logic
const dragging = ref(null);
const sliderWidth = ref(0);
const sliderLeft = ref(0);

const startDrag = (event, thumb) => {
  event.preventDefault();
  dragging.value = thumb;

  const slider = event.target.closest(".relative");
  sliderWidth.value = slider.offsetWidth;
  sliderLeft.value = slider.getBoundingClientRect().left;

  window.addEventListener("mousemove", onDrag);
  window.addEventListener("touchmove", onDrag);
  window.addEventListener("mouseup", stopDrag);
  window.addEventListener("touchend", stopDrag);
};

const onDrag = (event) => {
  if (!dragging.value) return;

  const clientX = event.touches ? event.touches[0].clientX : event.clientX;
  const position = clientX - sliderLeft.value;
  const percentage = Math.max(0, Math.min(100, (position / sliderWidth.value) * 100));
  const value =
    Math.round(((percentage / 100) * (props.max - props.min)) / props.step) * props.step +
    props.min;

  if (dragging.value === "min") {
    minValue.value = Math.min(value, maxValue.value - props.step);
  } else {
    maxValue.value = Math.max(value, minValue.value + props.step);
  }

  emit("change", [minValue.value, maxValue.value]);
};

const stopDrag = () => {
  dragging.value = null;
  window.removeEventListener("mousemove", onDrag);
  window.removeEventListener("touchmove", onDrag);
  window.removeEventListener("mouseup", stopDrag);
  window.removeEventListener("touchend", stopDrag);
};

onUnmounted(() => stopDrag());
</script>
