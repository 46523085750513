<template>
  <div :class="[containerClass]">
    <div
      class="h-28 bg-primary flex gap-4 justify-start items-center pl-5 sm:pl-28 xs:pl-0 xs:justify-center"
    >
      <NuxtImg alt="Erfolg" src="/icons/vehicle-finder.svg" class="h-12" />
      <div class="flex flex-col text-white">
        <span v-if="green" class="font-semibold text-base sm:text-xl md:text-2xl"
          >GRÜNER-FIRMENWAGEN-FINDER</span
        >
        <span v-else class="font-semibold text-base sm:text-xl md:text-2xl"
          >FIRMENWAGEN-FINDER</span
        >
        <span class="text-xs sm:text-base md:text-xl"
          >Unabhängig - interaktiv. Für alle Branchen.</span
        >
      </div>
    </div>
    <div
      class="flex bg-white"
      :class="{
        'sm:shadow-lg sm:rounded-br-3xl ': !popup,
      }"
    >
      <div class="flex flex-col pr-2 sm:pr-0 w-44 border-gray-300 transition-all duration-300">
        <button
          class="group flex flex-col items-center py-5 hover:bg-gray-200"
          :class="{ 'bg-gray-300': isFuelSelected(3) }"
          @click="changeFuel(3)"
        >
          <NuxtImg
            v-if="isFuelSelected(3)"
            alt="Erfolg"
            src="/icons/finder/electric-green.svg"
            class="h-8"
          />
          <NuxtImg v-else alt="Erfolg" src="/icons/finder/electric-grey.svg" class="h-8" />
          <span class="text-sm mt-2 text-gray-400" :class="{ 'text-primary': isFuelSelected(3) }"
            >Elektro</span
          >
        </button>
        <button
          class="group flex flex-col items-center py-4 hover:bg-gray-200"
          :class="{
            'bg-gray-300': isFuelSelected(5),
          }"
          @click="changeFuel(5)"
        >
          <NuxtImg
            v-if="isFuelSelected(5)"
            alt="Erfolg"
            src="/icons/finder/plugin-hybrid-green.svg"
            class="h-8"
          />
          <NuxtImg v-else alt="Erfolg" src="/icons/finder/plugin-hybrid-grey.svg" class="h-8" />
          <span
            class="text-sm mt-2 text-gray-400"
            :class="{
              'text-primary': isFuelSelected(5),
            }"
            >Plug-in-Hybrid</span
          >
        </button>
        <button
          class="group flex flex-col items-center py-4 hover:bg-gray-200"
          :class="{ 'bg-gray-300': isFuelSelected(4) }"
          @click="changeFuel(4)"
        >
          <NuxtImg
            v-if="isFuelSelected(4)"
            alt="Erfolg"
            src="/icons/finder/hybrid-green.svg"
            class="h-8"
          />
          <NuxtImg v-else alt="Erfolg" src="/icons/finder/hybrid-grey.svg" class="h-8" />
          <span class="text-sm mt-2 text-gray-400" :class="{ 'text-primary': isFuelSelected(4) }"
            >Hybrid</span
          >
        </button>

        <button
          v-if="!green"
          class="group flex flex-col items-center py-4 hover:bg-gray-200"
          :class="{
            'bg-gray-300': isFuelSelected(1),
          }"
          @click="changeFuel(1)"
        >
          <NuxtImg
            v-if="isFuelSelected(1)"
            alt="Erfolg"
            src="/icons/finder/benzin-green.svg"
            class="h-8"
          />
          <NuxtImg v-else alt="Erfolg" src="/icons/finder/benzin-grey.svg" class="h-8" />
          <span
            class="text-sm mt-2 text-gray-400"
            :class="{
              'text-primary': isFuelSelected(1),
            }"
            >Benzin</span
          >
        </button>
        <button
          v-if="!green"
          class="group flex flex-col items-center py-4 hover:bg-gray-200"
          :class="{
            'bg-gray-300': isFuelSelected(2),
          }"
          @click="changeFuel(2)"
        >
          <NuxtImg
            v-if="isFuelSelected(2)"
            alt="Erfolg"
            src="/icons/finder/diesel-green.svg"
            class="h-8"
          />
          <NuxtImg v-else alt="Erfolg" src="/icons/finder/diesel-grey.svg" class="h-8" />
          <span
            class="text-sm mt-2 text-gray-400"
            :class="{
              'text-primary': isFuelSelected(2),
            }"
            >Diesel</span
          >
        </button>
      </div>
      <div
        ref="form"
        class="sm:p-12 overflow-hidden duration-300 flex flex-col sm:gap-4 justify-center w-full"
      >
        <div
          class="grid grid-cols-2 mb-8 sm:pb-0 sm:grid-cols-3 lg:grid-cols-5 gap-2 sm:gap-4"
          :class="{
            'gap-4 ': popup,
          }"
        >
          <FormMultiSelect
            v-model="formData.categories"
            class="*:border-gray-300"
            :options="categories"
            :search-box="false"
            key-name="name"
            key-value="value"
            placeholder="Kategorie"
            multiple
            @focus="loadOptions"
            @change="updateData"
          />

          <FormMultiSelect
            v-model="formData.brand_id"
            class="*:border-gray-300"
            :options="options.brands"
            :search-box="options.brands.length > 3"
            key-name="name"
            key-value="value"
            placeholder="Marke"
            multiple
            @focus="loadOptions"
            @change="updateData"
          />

          <FormMultiSelect
            v-model="formData.model_id"
            class="*:border-gray-300"
            :options="options.models"
            :search-box="options.models.length > 3"
            key-name="name"
            key-value="value"
            placeholder="Modell"
            multiple
            @focus="loadOptions"
            @change="updateData"
          />

          <FormMultiSelect
            v-model="formData.type_id"
            class="*:border-gray-300"
            :search-box="false"
            :options="options.types"
            key-name="name"
            key-value="value"
            name="type_id"
            placeholder="Fahrzeugart"
            multiple
            @focus="loadOptions"
            @change="updateData"
          />

          <FormMultiSelect
            v-model="formData.transmission_id"
            class="*:border-gray-300"
            :search-box="false"
            :options="options.transmissions"
            key-name="name"
            key-value="value"
            name="transmission_id"
            placeholder="Getriebe"
            multiple
            @focus="loadOptions"
            @change="updateData"
          />

          <FormCheckbox
            v-model="formData.top_deal"
            label="Top Deals"
            label-class="truncate"
            class="*:h-full border-gray-300 *:px-2.5"
            :disabled="!options.top_deals"
            @change="updateData"
          />
        </div>
        <div
          class="grid grid-cols-2 sm:pb-0 sm:grid-cols-3 lg:grid-cols-3 gap-2 sm:gap-16 mb-12 mx-2.5"
        >
          <UiSlider
            v-if="account"
            v-model="formData.discount"
            :label="'Kondition'"
            :unit="'%'"
            :min="0"
            :max="options.slider.discount.max ?? 100"
            @update:model-value="updateBySlider"
          />

          <UiSlider
            v-if="isFuelSelected(3) || isFuelSelected(5)"
            v-model="formData.range"
            :label="'Reichweite'"
            :unit="' km'"
            :min="0"
            :max="options.slider.range.max ?? 100"
            @update:model-value="updateBySlider"
          />

          <UiSlider
            v-if="isFuelSelected(3) || isFuelSelected(5)"
            v-model="formData.chargingSpeed"
            :label="'Ladedauer'"
            :unit="' min'"
            :min="0"
            :max="options.slider.chargingSpeed.max ?? 100"
            @update:model-value="updateBySlider"
          />
        </div>
        <FrontpageButton
          class="max-sm:col-span-2 h-10 w-full text-xs sm:text-base !leading-none"
          :class="[{ 'col-start-5': isFuelSelected(3) || isFuelSelected(5) }]"
          :loading="state.loading"
          size="small"
          @click="submitForm() && scrollToVehicles()"
        >
          <span v-if="resultCount">{{ resultCount }} Ergebnisse anzeigen</span>
          <span v-else>suchen</span>
        </FrontpageButton>
        <div
          v-if="isFuelSelected(3) || isFuelSelected(5)"
          class="flex items-center min-w-full gap-x-2 cursor-pointer text-gray-500 col-span-2 group pt-3 -mb-8 sm:pt-1 sm:-mb-10"
          @click="eCarDisclaimerModal.show = true"
        >
          <Icon name="info" :size="20" :weight="500" />

          <span class="text-sm group-hover:underline">Reichweite &amp; Ladedauer</span>
        </div>
      </div>
    </div>
  </div>
  <Modal v-model="eCarDisclaimerModal.show">
    <Card class="m-auto w-full max-w-lg">
      <template #label>
        <span class="px-4 pb-1.5 pt-2 block" v-html="eCarDisclaimerModal.question"></span>
      </template>
      <div class="px-4 py-2" v-html="eCarDisclaimerModal.answer"></div>
    </Card>
  </Modal>
</template>

<script setup>
import { categories } from "~/server/variables/vehicle.js";
import { watch } from "vue";
import _ from "lodash";
const { debounce } = _;

const props = defineProps({
  query: {
    type: Object,
    default: {},
  },

  popup: {
    type: Boolean,
    default: false,
  },

  green: {
    type: Boolean,
    default: false,
  },

  containerClass: {
    type: String,
    default: "",
  },

  disableBrandSelect: {
    type: Boolean,
    default: true,
  },
  immediateSubmit: {
    type: Boolean,
    default: false,
  },
  enableTopDeals: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(["update"]);

const router = useRouter();
const { account } = useStore();

const state = reactive({
  loaded: false,
  loading: false,
  timeout: null,
  scrolled: false,
});

defineExpose({
  state,
});

// -------------------------------------------------------------------------

const eCarDisclaimerModal = reactive({
  show: false,
  question: "Hinweis zur Reichweite & Ladedauer",
  answer:
    "Bei der Angabe der Reichweite wird eine kombinierte Reichweite angenommen sowie bei der Ladedauer in Minuten von 0 - 80 % (alle Details müssen bitte direkt beim Hersteller eingesehen werden).",
});

const formData = reactive({
  categories: [],
  brand_id: props.query.brand_id ?? [],
  model_id: props.query.model_id ?? [],
  fuel_id: props.query.fuel_id ?? [3],
  type_id: props.query.type_id ?? [],
  transmission_id: props.query.transmission_id ?? [],
  top_deal: props.enableTopDeals ? true : Boolean(props.query.top_deal),
  commercial: Boolean(props.query.commercial),
  discount: props.query.discount ?? [],
  range: props.query.range ?? [],
  chargingSpeed: props.query.chargingSpeed ?? [],
});

const options = ref({
  fuels: [],
  types: [],
  brands: [],
  models: [],
  transmissions: [],
  top_deals: false,
  commercials: false,
  slider: {
    discount: [0, 100],
    range: [0, 100],
    chargingSpeed: [0, 100],
  },
});

const form = ref(null);
const resultCount = ref(null);

const green = computed(() => Boolean(props.green));

// -------------------------------------------------------------------------

const loadVehicleCount = async () => {
  state.loading = true;

  const query = Object.fromEntries(
    Object.entries({
      ...formData,
      green: props.green,
    }).filter(([_, value]) => value)
  );

  try {
    const { total } = await $fetch(`/api/finder/query`, {
      method: "POST",
      body: {
        query: {
          ...query,
          green: props.green,
        },
      },
    });

    resultCount.value = total ?? 0;
  } catch (error) {
    console.error("Error fetching data:", error);
    resultCount.value = 0;
  }
  state.loading = false;
};

const loadOptions = async () => {
  if (state.loaded) {
    return;
  }

  const query = Object.fromEntries(
    Object.entries({
      ...formData,
      green: props.green,
    }).filter(([_, value]) => value)
  );

  const data = await $fetch("/api/finder/options", {
    method: "POST",
    body: {
      query: {
        ...query,
        green: props.green,
      },
    },
  });

  for (const key in data) {
    options.value[key] = data[key];
  }

  formData.discount = [0, options.value.slider.discount.max ?? 100];

  formData.range = [0, options.value.slider.range.max ?? 100];

  formData.chargingSpeed = [0, options.value.slider.chargingSpeed.max ?? 100];

  state.loaded = true;
  clearTimeout(state.timeout);
};

const submitForm = async () => {
  state.loading = true;

  const data = Object.fromEntries(Object.entries(formData).filter(([_, value]) => value));

  if (router.currentRoute.value.query.sort) {
    data.sort = router.currentRoute.value.query.sort;
  } else {
    delete data.sort;
  }

  if (formData.range) data.range = formData.range;
  if (formData.chargingSpeed) data.chargingSpeed = formData.chargingSpeed;
  if (account && formData.discount) data.discount = formData.discount;

  const params = new URLSearchParams({
    ...data,
  }).toString();

  const route = props.green ? "gruener-firmenwagen-finder/explorer" : "firmenwagen-finder/explorer";

  if (props.query) {
    console.log(params);
    navigateTo(`/${route}?${params}`).then(() => {
      emits("update", data, state);
    });
    state.loading = false;
    return;
  }

  await navigateTo(`/${route}?${params}`);
  state.loading = false;
};

const isFuelSelected = (id) => {
  return formData.fuel_id.includes(id);
};

const changeFuel = (id) => {
  if (formData.fuel_id.includes(id)) {
    formData.fuel_id = formData.fuel_id.filter((fuel) => fuel !== id);
  } else {
    formData.fuel_id = [...formData.fuel_id, id];
  }
  updateData();
};

const debouncedLoad = debounce(() => {
  loadVehicleCount();
  if (props.immediateSubmit) {
    submitForm();
  }
}, 500);

const updateBySlider = () => {
  state.loaded = false;
  debouncedLoad();
};

const updateData = () => {
  state.loaded = false;
  loadOptions().then(() => {
    loadVehicleCount();

    if (props.immediateSubmit) {
      submitForm();
    }
  });
};

const scrollToVehicles = () => {
  const element = document.querySelector("#fahrzeuge");
  if (!element) return;

  const offset = 100;
  const y = element.getBoundingClientRect().top + window.pageYOffset - offset;

  window.scrollTo({
    top: y,
    behavior: "smooth",
  });
};

// -------------------------------------------------------------------------

watch(options, (value) => {
  if (!value.top_deals) {
    formData.top_deal = null;
  }

  if (!value.commercials) {
    formData.commercial = null;
  }
});

watch(
  () => formData.model_id,
  (newModelId) => {
    if (newModelId) {
      const selectedModel = options.value.models.find((model) => model.value === newModelId);
      if (selectedModel) {
        formData.brand_id = selectedModel.brand_id;
      }
    }
  }
);

onMounted(() => {
  loadOptions().then(() => {
    loadVehicleCount();
    scrollToVehicles();
  });
});

onUnmounted(() => {
  clearTimeout(state.timeout);
});
</script>
